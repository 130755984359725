import Vue from 'vue'

import 'reset-css'
import App from './App.vue'
import router from '@/router/index'
import store from '@/store'
import '@icon-park/vue/styles/index.css'
// buefy 组件库
// import Buefy from 'buefy';
// import 'buefy/dist/buefy.css'
// element-ui组件库
import { 
  Button, Message, Menu, MenuItem, Submenu, Breadcrumb, BreadcrumbItem, 
  Dropdown, DropdownMenu, DropdownItem, Tag, Form, FormItem, Radio, RadioGroup,
  Input, InputNumber, Table, TableColumn, Pagination, Dialog, Select, Option, Link, DatePicker,
  Checkbox, CheckboxGroup, MessageBox, Avatar, Tooltip, Divider, Badge, Card, 
  Descriptions, DescriptionsItem, Empty, Popover, Row, Col, Loading, Alert, Switch,
  Notification, Tabs, TabPane, Popconfirm, RadioButton, Cascader, Statistic, PageHeader, result,
  image, Backtop, InfiniteScroll, Drawer, Collapse, CollapseItem,
} from 'element-ui';
// import InfiniteLoading from 'vue-infinite-scroll';
import "@/utils/filters"
import "@/utils/globalFunction";
//引入RSA第三方库

// Vue.use(Buefy);


Vue.use(Button);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Tag);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Select);
Vue.use(Option);
Vue.use(Link);
Vue.use(DatePicker);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Avatar);
Vue.use(Tooltip);
Vue.use(Divider);
Vue.use(Badge);
Vue.use(Card);
Vue.use(Descriptions);
Vue.use(DescriptionsItem);
Vue.use(Empty);
Vue.use(Popover);
Vue.use(Row);
Vue.use(Col);
Vue.use(Loading.directive);
Vue.use(Alert);
Vue.use(Switch);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Popconfirm);
Vue.use(RadioButton);
Vue.use(Cascader);
Vue.use(Statistic);
Vue.use(PageHeader);
Vue.use(result);
Vue.use(image);
Vue.use(Backtop);
Vue.use(InfiniteScroll);
Vue.use(Drawer);
Vue.use(Collapse);
Vue.use(CollapseItem);


Vue.prototype.$loading = Loading.service;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$notify = Notification;


Vue.config.productionTip = false


new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')


//配置
// var url = "http://192.168.2.22:8989";

// export default url;
